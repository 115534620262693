class PaidInvoiceRefresh {
    initialize() {
    }
}

export class PaidInvoiceRefreshUpdater {
  constructor(refreshUrl, callback = null) {
    this.refreshUrl = refreshUrl
    self = this;
    $.ajax({
      url: this.refreshUrl,
      context: this,
      dataType: "json"
    }).done(function (data) {
      self.updateUI(data);
    });
    this.callback = callback;
    this.boundUrl = window.location.href;
  }
  updateUI(data) {
    if (this.boundUrl !== window.location.href) {
      console.warn('canceling updates due to page navigation change, probably due to turbolinks');
      this.callback(false, 'page_change', data);
    } else if (data) {
      if (this.callback !== null) {
        this.callback(true, data)
      }
    } else {
      if (this.callback !== null) {
        this.callback(false, data);
      }
    }
  }
}
export default new PaidInvoiceRefresh()
