
class SelectPickerHelper {
    constructor(selector, debug=false) {
        this.selector = selector
        this.selectChangedCallBacks = [];
        this.debug = debug
        
    }
    initialize() {
        $(this.selector).selectpicker();
        this._bindEvents();

    }
    addListener(f) {
        this.selectChangedCallBacks.push(f)
        return this;
    }
    clearListeners() {
        this.selectChangedCallBacks = [];
        return this;
    }
    destroy() {
        this.clearListeners();
        $(this.selector).selectpicker('destroy')
    }
    selectChangedCallback(e, clickedIndex, newValue, oldValue) {
        var self = e.data;
        delete e.data
        try {
            var selected_value = ""
            var selected_text = ""
            var opt_value = null;
            var id=e.target.id;
            var selected_option = $(this).find(":selected"); // get selected option for the changed select only
            selected_value = selected_option.val();
            selected_text = selected_option.text();
            parent = selected_option.parent()
            if (parent.is("optgroup")) {
                opt_value = parent.attr('label');
            }
            for (var i=0; i < self.selectChangedCallBacks.length; i++) {
                var ret = self.selectChangedCallBacks[i](id, selected_value, selected_text, opt_value, selected_option, clickedIndex)
                if (ret !== undefined && !ret) {
                    self._log('stopping propagation')
                    break;
                }
            }
        } catch(e) {
            self._log("error: " + e.message)
            console.error(e)
        }
        self._log("callbacks complete");
    }
    render() {
        $(this.selector).selectpicker('render');
        this._bindEvents();
    }
    // Private Methods
    _bindEvents() {
        $(this.selector).on('changed.bs.select', 
            this, /* Pass Reference to Self */
            this.selectChangedCallback);
    }
    _log(msg) {
        if (this.debug) console.log(msg)
    }

}

export default SelectPickerHelper