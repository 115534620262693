function selectorFromId(id, parent = "") {
  let parent_selector = " "
  if (parent !== "") {
    parent_selector = (parent.startsWith("#") ? "" : "#") + parent + " "
  }
  return parent_selector + (id.startsWith("#") ? "" : "#") + id;
}

class CardFilter {
  constructor() {
    this.filterInputSelector = null;
    this.cardGroupSelector =  null;
    this.fieldSeperator = "|";
  }
  bindCards(filter_id, card_container_id, fieldSeperator="|", parent = "") {
    if (this.filterInputSelector !== null) {
      $(this.filterInputSelector).off('keyup');
    }
    this.filterInputSelector = selectorFromId(filter_id, parent);
    this.cardGroupSelector = selectorFromId(card_container_id, parent) + " div[data-filterable-card]";
    const self = this;
    $(this.filterInputSelector).on('keyup', function () {
      let search = $(this).val();
      console.log(search)
      const that = self;
      $(self.cardGroupSelector).each(function() {
        if (that.anyMatch($(this), search)) {
          console.log($(this));
          $(this).show();
        } else {
          $(this).fadeOut();
        }
      });
    });
    $(this.filterInputSelector).val("")
    $(this.cardGroupSelector).each(function () {
      $(this).show();
    })
  }
  anyMatch(card, filter) {
    if (filter.trim() === '') {
      return true;
    }
    var re = new RegExp(filter, "i");
    let data = card.attr("data-filterable-fields").split(this.fieldSeperator);
    for (var val of data) {
      if (re.test(val)) {
        return true;
      }
    }
    return false;
  }
}
export default new CardFilter();