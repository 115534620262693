function selectorFromId(id, parent = "") {
  let parent_selector = " "
  if (parent !== "") {
    parent_selector = (parent.startsWith("#") ? "" : "#") + parent + " "
  }
  return parent_selector + (id.startsWith("#") ? "" : "#") + id;
}
class TableFilter {
  constructor() {
    this.filterInputSelector = null;
    this.tableRowSelector = null;
    this.cols = [];
    // This allows for an additional filter logic to be applied, outside of standard attributes
    // return true to continue filter, false to remove 
    this.filterCallback = undefined;
  }
  
  bindTable(filter_id, table_id, cols = [], parent = "", filterCallback = undefined) {

    this.filterCallback = filterCallback;
    if (this.filterInputSelector !== null) {
      $(this.filterInputSelector).off('keyup');
    }
    this.filterInputSelector = selectorFromId(filter_id, parent);
    this.tableRowSelector = selectorFromId(table_id, parent) + " tr.a-filterable-row";
    this.cols = cols;
    // console.log(`Input Selector ${this.filterInputSelector} Table Selector ${this.tableRowSelector}`)
    const self = this;
    $(this.filterInputSelector).on('keyup', function () {

      let search = $(this).val();
      // For Each TR
      const that = self
      $(self.tableRowSelector).each(function () {
        let match = that.anyMatch($(this), search);
        if (match) {
          $(this).show();
        } else {
          $(this).fadeOut();
        }
        that.handleExpanded($(this),match);
      })
    })
    this.refresh(true);

  }
  handleExpanded(row, show) {
    if (row.hasClass('expandable-row')) {
      let elem = $("#" + row.data('details-id'));
      if (show) {
        elem.show();
      } else {
        elem.hide();
      }
    } else if (row.attr('data-row-id')) {
      let id = row.attr('data-row-id');
      console.log(id);
      $(`tr[data-row-parent="${id}"]`).each(function() {
        show ? $(this).show() : $(this).hide();
      })
    }
  }
  refresh(hideWhileUpdating=false) {
    if (hideWhileUpdating) {
      $(this.tableRowSelector).parent().hide();
    }
    this.updateRowState();
    if (hideWhileUpdating) {
      $(this.tableRowSelector).parent().show();
    }
  }
  updateRowState() {
    let that = this;
    $(this.filterInputSelector).val("")
    $(this.tableRowSelector).each(function () {
      if (that.anyMatch($(this), "")) {
        $(this).show();
        that.handleExpanded($(this), true);
      } else {
        $(this).hide();
        that.handleExpanded($(this), true);
      }
    })
  }
  anyMatch(row, filter) {
    if (!this.doFilterCallback(row, filter)) {
      return false;
    }
    if (filter.trim() === '') {
      return true;
    }
    let idx = this.cols.findIndex((elem) => {
      let col = row.find("td:nth-child(" + elem + ")").text();
      if (col.search(new RegExp(filter, "i")) != -1) {
        return true;
      }
    })
    return idx !== -1;
  }
  doFilterCallback(row, filter) {
    if (this.filterCallback === undefined) {
      return true;
    }
    return this.filterCallback(row, filter);
  }

}
export default new TableFilter();