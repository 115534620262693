import consumer from "./consumer"

import jQuery from 'jquery';
const NO_NOTIFICATIONS_MESSAGE = "No new notifications";
const NEW_NOTIFICATIONS_MESSAGE = {
  "singular": "##count## new notification",
  "plural": "##count## new notifications"
};
const notificationChannel = consumer.subscriptions.create("NotificationChannel", {
  connected() {
    this.getNotifications();
  },
  received(data) {
    try {
      let message = NO_NOTIFICATIONS_MESSAGE
      console.log(data);
      if (data.action === "new_notification") {
        let count = parseInt(data.message);
        let notification_link = jQuery("#nav_notification_link")
        let notification_icon = jQuery("#nav_notification_icon")
        if (count === 0) {
          notification_icon.removeAttr("data-count");
        } else {
          let num = count;
          if (count > 9) {
            num = "9+";
          }
          message = (count == 1 ? NEW_NOTIFICATIONS_MESSAGE.singular : NEW_NOTIFICATIONS_MESSAGE.plural).replace("##count##", count);
          notification_icon.attr("data-count", num);
        }

        notification_link.attr('data-original-title', message).tooltip('update');
        notification_link.removeAttr('title')
      }
    } catch(err) {
      console.log(`NotificationChannel error ${err.msg}, ${err.stack}`);
    }
  },
  getNotifications() {
    this.perform("get_notification_count");
  }
})

function notificationChannelTurboLinksLoad() {
  notificationChannel.getNotifications();
}

document.addEventListener("turbo:load", notificationChannelTurboLinksLoad);