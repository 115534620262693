
const TYPE_OMD_SUMMARY = 1;
const TYPE_OMD_OMD = 2;
const TYPE_OMD_OD = 3;
const TYPE_OMD_PRODUCT = 4;

const TYPE_OD_SUMMARY = 5;
const TYPE_OD_OMD_PRACTICE = 6;
const TYPE_OD_OMD = 7;
const TYPE_OD_OD = 8;

const TYPE_ASC_SUMMARY = 9;
const TYPE_ASC_OMD_PRACTICE = 10;
const TYPE_ASC_OMD = 11;

const FILTER_ID_ALL = -1;
const EVT_FILTER_CHANGE = "cofi:report:filterChange";
const EVT_REPORT_DATA_LOADED = "cofi:report:data";
const CHART_DATE_DISP_FMT = "L";


class CofiReporting {

  constructor(ns, selector) {
    this.ns = ns
    this.selector = selector
    this.prevType = null;
    this.prevFilter = null;
    this.current_data_set = null;
    this.chart = null;
  }

  loadData(groupBy, type, filter) {
    var self = this;
    if (this.prevType === type && this.prevFilter === filter) {
      return;
    }

    $.ajax({
      url: this._getUrl(type, filter)
    }).done(function (data) {

      self.prevType = type;
      self.prevFilter = filter;

      self.current_data_set = self._formatData(data);

      self.drawChart(groupBy)
    });
  }

  drawChart(groupBy = 'day') {
    if (this.current_data_set == null)
      return;

    this.clearChart();

    var ctx = $(this.selector);
    var dataSet = this.current_data_set.daily;

    if (groupBy === "week") {
      dataSet = this.current_data_set.weekly;
    }

    this.chart = new Chart(ctx, this._barChart(dataSet.labels, dataSet.data));
  }

  clearChart() {
    if (this.chart !== null) {
      this.chart.destroy();
    }
  }
  /* PRIVATE */
  _formatData(data, byWeek) {
    var ret = {
      hasData: false,
      daily: {
        labels: [],
        data: []
      },
      weekly: {
        labels: [],
        data: []
      }
    }
    var woy = new Map();
    data.forEach((elem) => {
      if (!ret.hasData) {
        ret.hasData = true
      }
      var d = moment(elem.day);
      ret.daily.labels.push(d.format(CHART_DATE_DISP_FMT))
      ret.daily.data.push(elem.sum_in_cents / 100)
      // key in format of "YYYY-WEEK"
      var key = d.weekYear() + "-" + d.week();
      if (!woy.has(key)) {
        woy.set(key, {
          label: d.startOf('week').format(CHART_DATE_DISP_FMT),
          count: 0,
          sum: 0
        });
      }
      let val = woy.get(key);
      val.count += elem.num;
      val.sum += elem.sum_in_cents;
      woy.set(key, val);
    })
    woy.forEach((value) => {
      ret.weekly.labels.push(value.label)
      ret.weekly.data.push(value.sum / 100);
    })
    return ret;
  }

  _getUrl(type, filter) {
    var url = "/" + this.ns + "/reports/data.json?"
    if (!!type) {
      url += "&report_type=" + type
    }
    if (!!filter) {
      url += "&filter_id=" + filter
    }
    return url
  }

  // rgba(252, 123, 3, 0.75)
  _barChart(labels, data) {
    return {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: '$',
          data: data,
          backgroundColor: '#44a1df',
          borderColor: '#44a1df',
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              drawOnChartArea: false
            },
            scaleLabel: {
              display: true,
              labelString: 'Total Revenue'
            }
          }],
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            },
            scaleLabel: {
              display: true,
              labelString: 'Date'
            }
          }]
        },
        legend: {
          display: false
        }
      }
    }
  }

  /* GETTERS */
  get type() {
    return this.prevType;
  }

  get filter() {
    return this.prevFilter;
  }

  get EVT_FILTER_CHANGE() {
    return EVT_FILTER_CHANGE
  }
  // For Non Babel Access
  filterChangeEventName() {
    return EVT_FILTER_CHANGE;
  }

  get EVT_REPORT_DATA_LOADED() {
    return EVT_REPORT_DATA_LOADED;
  }
  // For Non Babel Access
  reportDataLoadedEventName() {
    return EVT_REPORT_DATA_LOADED
  }

  get TYPE_OMD_SUMMARY() {
    return TYPE_OMD_SUMMARY;
  }

  get TYPE_OMD_OMD() {
    return TYPE_OMD_OMD;
  }

  get TYPE_OMD_OD() {
    return TYPE_OMD_OD;
  }

  get TYPE_OMD_PRODUCT() {
    return TYPE_OMD_PRODUCT;
  }

  get TYPE_OD_SUMMARY() {
    return TYPE_OD_SUMMARY;
  }

  get TYPE_OD_OMD_PRACTICE() {
    return TYPE_OD_OMD_PRACTICE
  }

  get TYPE_OD_OMD() {
    return TYPE_OD_OMD;
  }

  get TYPE_OD_OD() {
    return TYPE_OD_OD;
  }

  get TYPE_ASC_SUMMARY() {
    return TYPE_ASC_SUMMARY
  }

  get TYPE_ASC_OMD_PRACTICE() {
    return TYPE_ASC_OMD_PRACTICE
  }

  get TYPE_ASC_OMD() {
    return TYPE_ASC_OMD
  }

  get FILTER_ID_ALL() {
    return FILTER_ID_ALL;
  }
}

export default CofiReporting