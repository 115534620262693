require("es6-symbol/implement");

import Enum from "es6-enum"

export const NavLinks = Enum("PENDING_CASES", "PAID_CASES", "DEPOSITS",
  "SEARCH_PATIENTS", "REPORTS", "OMDS", "USERS", "SETTINGS", "SUPPORT",
  "PAID_INVOICES", "UNPAID_INVOICES", "PENDING_FINANCING_INVOICES");
const ID_MAP = new Map([
  [NavLinks.PENDING_CASES, "sidenav_pending_cases"],
  [NavLinks.PAID_CASES, "sidenav_paid_cases"],
  [NavLinks.DEPOSITS, "sidenav_deposits"],
  [NavLinks.SEARCH_PATIENTS, "sidenav_search_patients"],
  [NavLinks.REPORTS, "sidenav_reports"],
  [NavLinks.OMDS, "sidenav_omds"],
  [NavLinks.USERS, "sidenav_users"],
  [NavLinks.SETTINGS, "sidenav_settings"],
  [NavLinks.SUPPORT, "sidenav_support"],
  [NavLinks.UNPAID_INVOICES, "sidenav_unpaid_invoices"],
  [NavLinks.PAID_INVOICES, "sidenav_paid_invoices"],
  [NavLinks.PENDING_FINANCING_INVOICES, "sidenav_pending_financing_invoices"],
]);

export class Navigation {
  static highlight(navLink) {
    if (!ID_MAP.has(navLink)) {
      console.error(`highlight() - NavLink ${navLink} not found`);
      return;
    }
    let id = ID_MAP.get(navLink);
    this.allOff()
    this.setHighlight(id, true);
  }
  static allOff() {
    for(const elem of ID_MAP.values()) {
      this.setHighlight(elem, false);
    }
  }
  static setHighlight(id, on) {
    try {
      let elem = $("#"+id).children().first();
      if (on) {
        elem.addClass('active')
      } else {
        elem.removeClass('active')
      }
    } catch(e) {
      console.error(`setHighlight() - error ${e.message} ${e.stack}`)
    }
  }
}