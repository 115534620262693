$.maintainSSN = function(key, keyCode){
  if($('#ssn_masked').val() == '') {
    $("#ssn_masked").val('')
    $("#ssn").val('')
    return
  }
  var ssn = $('#ssn').val() ?? ''
  if (!(/^[0-9]$/i.test(key)) && keyCode != 8) { return } 
  if (ssn.length > 8 && keyCode != 8) { return } 
  if (keyCode == 8){
    $('#ssn').val(ssn.slice(0, $("#ssn_masked").val().replaceAll('-', '').length))
  }
  else{
    $('#ssn').val(ssn + key)
  }
}

$.manage_eye_toggle = function(){
  $('#ssn_masked_toggle').toggleClass("fa-eye fa-eye-slash");
  $.ssn_visibility();
}

$.maskSSN = function(){
  var org_val = $('#ssn').val()
  masked_val = org_val.slice(0,3).replace(/[0-9]/g,'X') + "-" + org_val.slice(3,5).replace(/[0-9]/g,'X') + "-" + org_val.slice(5,9)
  $('#ssn_masked').val(masked_val.replace(/-+$/, ''))
}

$.unmaskSSN = function(){
  var org_val = $('#ssn').val()
  masked_val = org_val.slice(0,3) + "-" + org_val.slice(3,5)+ "-" + org_val.slice(5,9)
  $('#ssn_masked').val(masked_val.replace(/-+$/, ''))
}

$.enable_search_btn = function(){
  $("#submit").prop('disabled', false);
  $("#submit").prop("value", "Search");
}

$.disable_search_btn = function(){
  $("#submit").prop('disabled', true);
  $("#submit").prop("value", "Searching...");
}

$.manage_option_tab = function(obj,id){
  if(obj.id == id){
    $(obj).addClass("active");
  }else{
    $(obj).removeClass("active");
  }
}

$.ssn_visibility = function(){
  if($('#ssn_masked_toggle').hasClass("fa-eye")){
    $.maskSSN()
  }
  else{
    $.unmaskSSN()
  }
}

