class WelcomeMessage {
  static updateWelcomeMessage(selector) {
    let first_name = $("meta[name='cofi-user-first-name']").attr('content') || '?';
    let msg = 'Welcome, ' + first_name;
    let hour = new Date().getHours();
    if (hour < 12) {
      msg = 'Good morning, ' + first_name;
    } else if (hour < 18) {
      msg = 'Good afternoon, ' + first_name;
    } else {
      msg = 'Good evening, ' + first_name;
    }
    $(selector).text(msg);
  }
}

export default WelcomeMessage