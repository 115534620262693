import consumer from "./consumer";

const optionPresent = (selectElement, optionValue) => $(selectElement).find(`option[value="${optionValue}"]`).length > 0;
const selectedOption = element => $(element).find('option:selected').val();
const appendOption = (optionValue, selected = "") => `<option value="${optionValue}"${selected}>${optionValue}</option>`;

consumer.subscriptions.create("AdjustmentOptionsChannel", {
  received(data) {
    const selectClass = `.${data.practice}-description-${data.omd_id}`;

    const optionElement = $(`${selectClass} option`).filter(function() {
      return $(this).text() === 'Click “Manage Descriptions” to add/edit descriptions';
    });

    switch (data.action_performed) {
      case 'create':
        $(selectClass).each(function() {
          if (!optionPresent(this, data.option)) {
              $(this).append(appendOption(data.option));
          }
        });
        optionElement.remove();
        break;
      case 'update':
        $(`${selectClass} option[value="${data.old_value}"]`).text(data.option).val(data.option);
        break;
      case 'delete':
        $(selectClass).each(function() {
          if (selectedOption(this) !== data.old_value) {
              $(this).find(`option[value="${data.old_value}"]`).remove();
          }
        });
        if (data.all_deleted && !optionElement.length) {
          $(selectClass).append(appendOption('Click “Manage Descriptions” to add/edit descriptions', 'disabled'));
        }
        break;
      case 'reorder':
        $(selectClass).each(function() {
          const startIndex = $(this).find('option').length - data.option.length;
          const selectElement = this;
          const selectedOpt = selectedOption(selectElement);
          
          $(this).find('option').slice(startIndex).remove();
          data.option.forEach(option => $(selectElement).append(appendOption(option, selectedOpt === option ? "selected" : "")));
        });
        break;
      }
  }
});
