const MOR_SERVICE_AGREEMENT_ID = "merchant_of_record_service_agreement";
const MOR_PRICING_AGREEMENT_ID = "merchant_of_record_pricing_agreement";

export class VendorAgreements {
  constructor(buttonToEnableSelector) {
    this.btnSelector = buttonToEnableSelector;
    this.serviceAgreed = false;
    this.pricingAgreed = false;
    const self = this;
    $('input[type="checkbox"]').off('click').on('click', function () {
      var elem_id = $(this).prop('id')
      switch (elem_id) {
        case MOR_SERVICE_AGREEMENT_ID:
          self.serviceAgreed = $(this).prop("checked");
          break
        case MOR_PRICING_AGREEMENT_ID:
          self.pricingAgreed = $(this).prop("checked");
          break
        default:
          console.log("unknown element " + elem_id)
      }
      var btn = $(self.btnSelector)
      if (self.pricingAgreed && self.serviceAgreed) {
        btn.removeClass('disabled')
      } else {
        btn.addClass('disabled')
      }
    });
  }
}

export class MerchantForm {
  constructor() {
    $("a[data-mor-no-owner-info='true']").off().on('click', function () {
      // set the hidden field, hide the owner info form, show the primary contact form
      $("div#mor_owner_info_forms").addClass('d-none');
      $("div#mor_primary_contact_form").removeClass('d-none');
      $("input#merchant_of_record_no_ownership_info").val(true);
      return false;
    })
    $("a[data-mor-no-owner-info='false']").off().on('click', function () {
      // set the hidden field, hide the owner info form, show the primary contact form
      $("div#mor_owner_info_forms").removeClass('d-none');
      $("div#mor_primary_contact_form").addClass('d-none');
      $("input#merchant_of_record_no_ownership_info").val(false);
      return false;
    })
    $("a[data-mor-add-owner='true']").off().on('click', function () {
      if ($("div#mor_owner_2").hasClass('d-none')) {
        $("div#mor_owner_2").removeClass('d-none')
      } else if ($("div#mor_owner_3").hasClass('d-none')) {
        $("div#mor_owner_3").removeClass('d-none');
      } else if ($("div#mor_owner_4").hasClass('d-none')) {
        $("div#mor_owner_4").removeClass('d-none');
        $("div#mor_add_button_row").addClass('d-none');
      } else {
        $("div#mor_add_button_row").addClass('d-none');
      }
      return false;
    })

    $('#js__submit-application').one('click', function () {
      const owner_fields = $('input[name^="merchant_of_record[owner"]').filter(function () {
        return this.name.match(/merchant_of_record\[owner[1-4]_attributes\]\[gov.+\]/) && !this.disabled;
      });
      let missingGovtId = (owner_fields[0].value && !owner_fields[1].value) ||
          (owner_fields[2].value && !owner_fields[3].value) ||
          (owner_fields[4].value && !owner_fields[5].value) ||
          (owner_fields[6].value && !owner_fields[7].value);

      if (missingGovtId && !confirm('IDs are sometimes requested. You can submit this form without one, but please note that CoFi may call you to request one subsequently.')) {
        return false;
      }
    });
  }

}

export class MerchantOfRecords {

}

export default new MerchantOfRecords();

$.format_merchant_sales_vol =  function(){
  var sales_volume = $("input[name='merchant_of_record[sales_volume]']").val();
  const chars = {'$':'',',':''};
  $("input[name='merchant_of_record[sales_volume]']").val( sales_volume.replace(/[$,]/g, m => chars[m]) );
}
