// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.




import Cleave from 'cleave.js';

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
require("chart.js")
window.moment = require('moment');
import Inputmask from 'inputmask';
import select2 from "select2";
require("mutation-events");

// must come after jquery
require('./ajax_helper');
// see https://github.com/nathanvda/cocoon and https://github.com/dabroz/cocoon-js-vanilla
import "cocoon-js-vanilla";
import alphaeon from './alphaeon';
window.alphaeon = alphaeon;
import careCredit from './care_credit';
window.careCredit = careCredit;
import cofiInvoices from './invoices';
window.cofiInvoices = cofiInvoices;
import modalValidator from './modal_validator';
window.modalValidator = modalValidator;
import formHandler from './form_fields_practice_handler';
window.formHandler = formHandler;
import tabFinancingHandler from './tab_financing_handler';
window.tabFinancingHandler = tabFinancingHandler;
import SelectPickerHelper from './select-picker-helper';
window.cofiSelectPicker = new SelectPickerHelper('.selectpicker', false);
import CofiReporting from './cofi_reporting';
window.CofiReporting = CofiReporting;
import cofiMerchantOfRecord, { VendorAgreements, MerchantForm } from './merchant_of_record';
window.cofiMerchantOfRecord = cofiMerchantOfRecord;
window.CofiMerchantOfRecordVendorAgreement = VendorAgreements;
window.CofiMerchantOfRecordMerchantForm = MerchantForm;
import cofiPayable, { PaymentStatusUpdater } from './payable'
window.cofiPayable = cofiPayable;
window.cofiPayablePaymentStatusUpdater = PaymentStatusUpdater;

import cofiPayableMulti, { MultiPaymentStatusUpdater } from './payable_multi'
window.cofiPayableMulti = cofiPayableMulti;
window.cofiPayableMultiPaymentStatusUpdater = MultiPaymentStatusUpdater;

import cofiPayableFinanced, { FinancedStatusUpdater } from './financed_payable'
window.cofiPayableFinanced = cofiPayableFinanced;
window.cofiFinancedStatusUpdater = FinancedStatusUpdater;

import cofiPaidInvoiceRefresh, { PaidInvoiceRefreshUpdater } from './paid_invoice_refresh'
window.cofiPaidInvoiceRefresh = cofiPaidInvoiceRefresh;
window.cofiPaidInvoiceRefreshUpdater = PaidInvoiceRefreshUpdater;

import cofiTableFilter from './table_filter';
window.cofiTableFilter = cofiTableFilter;
import cofiCardFilter from './card_filter';
window.cofiCardFilter = cofiCardFilter;
import cofiProductPricingCalculator, { FacilityProductCalculator } from './product_pricing'
window.cofiPPCalculator = cofiProductPricingCalculator
window.cofiFacilityProductCalculator = FacilityProductCalculator;
import CofiWelcomeMessage from './welcome_message'
window.CofiWelcomeMessage = CofiWelcomeMessage;

import { NavLinks, Navigation } from './navigation'
window.NavLinks = NavLinks;
window.cofiNavigation = Navigation;

import { bindStopFormSubmitAndBack, DEFAULT_BLOCKING_SELECTOR } from './enter_key_stop'
window.bindStopFormSubmitAndBack = bindStopFormSubmitAndBack;


import 'trix'
import './stylesheets/trix.css'

import './bootstrap_custom.js'
import './stylesheets/application.scss'

import Rails from "@rails/ujs"
Rails.start();
window.Rails = Rails;

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

require("@rails/activestorage").start()
require("channels")
require('datatables.net-bs4')
require("datatables.net")
require("./stylesheets/custom_data_table_bootstrap4.css")
require("datetime-moment");
require("select2")

import 'data-confirm-modal'

window.dragula = require("dragula")
const clipboardy = require("clipboardy")

import 'dragula/dist/dragula.css'
// currency library
import currency from 'currency.js';
window.currency = currency;
// BigDecimal Implementation
import { Decimal } from 'decimal.js';
window.Decimal = Decimal;

require("chartkick")

require("bootstrap-select")


import bsCustomFileInput from 'bs-custom-file-input'
window.bsCustomFileInput = bsCustomFileInput

const dataTables = [];

window.adminBindDragDrop = function () {
  var drake = dragula([document.getElementById("drop-zone")], {
    revertOnSpill: true
  });
  const drop_url = $("#drop-zone").data("reorder-uri")
  console.log(drop_url)
  drake.on("drop", function (el, target, source, sibling) {
    // calculate the new order
    console.log("dropped")
    var ret = [];
    // see if there is a product group set, if so then update that order not the all order
    let filter_val = $("#drop-zone > input#active_product_group_filter").val()
    $("#drop-zone").children("div").each(function () {
      console.log(`${$(this).data("product")} - ${$(this).data("product-name")}`)
      ret.push($(this).data("product"))
    });
    window.$.ajax({
      url: drop_url,
      context: this,
      data: { order: ret, filter_val: filter_val },
      method: "POST"
    }).done(function (data) {
      console.log('done')
    });
  });
};

window.bindDragDrop = function (practice, disableDragDrop = false) {
  const zoneId = `${practice}-drop-zone`;
  const drakeKey = `${zoneId}Drake`;

  if (window[drakeKey]) {
    window[drakeKey].destroy();
  }

  if (disableDragDrop) {
    return;
  }
  window[drakeKey] = dragula([document.getElementById(zoneId)], {
    revertOnSpill: true
  });

  const dropUrl = $(`#${zoneId}`).data("reorder-uri");
  window[drakeKey].on("drop", function (el, target, source, sibling) {
    const order = [];
    $(`#${zoneId}`).children("div").each(function () {
      order.push(this.firstElementChild.dataset.product);
    });

    $.ajax({
      url: dropUrl,
      method: "POST",
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      data: { order: order, practice: practice }
    });
  });
};


window.cofiBindClickboardEvents = function () {
  $("button[data-cofi-clipboard-copy]").off("click").on("click", function () {
    clipboardy.write($(this).data('cofi-clipboard-content')).then(() => {
      window.alert('Copied to clipboard');
    })
  })
}

window.cofiLoadDataTables = function () {
  $("table[data-datatable]").each((_, element) => {
    dataTables.push($(element).DataTable({
      paging: false,
      order: [],
      searching: false,
      info: false,
      retrieve: true
    }));
  });
}

window.cofiDestroyDataTables = function () {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy()
  }
}

window.cofiReloadDataTable = function (id) {
  let idx = dataTables.findIndex(elem => elem.id === id)
  console.log(id);
  if (idx !== -1) {
    dataTables.slice(idx)
  }
  console.log($(`#${id}`));
  dataTables.push($(`#${id}`).DataTable({
    paging: false,
    order: [],
    searching: false,
    info: false,
    retrieve: true
  }));
}

window.cofiSwapClass = function (elem, oldClass, newClass) {
  elem.removeClass(oldClass).addClass(newClass);
}

window.cofiRenderSelectPicker = function () {
  $('.selectpicker').selectpicker('render');
}

document.addEventListener("turbo:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()


  bsCustomFileInput.init()
  // https://github.com/Slashek/bootstrap-select-rails/issues/31
  // $(".selectpicker").selectpicker();
  cofiSelectPicker.initialize();

  $(".has-tooltip").tooltip();

  if (location.hash) {
    $("a[href='" + location.hash + "']").tab("show");
  }
  $(document.body).on("click", "a[data-toggle='tab']", function (event) {
    history.replaceState(history.state, '', this.getAttribute("href"));
  });

  // invoke data tables
  cofiLoadDataTables();
  // bind clipboard routines
  cofiBindClickboardEvents();


  if ($.fn.hasOwnProperty('datepicker')) {
    $.fn.datepicker.defaults.format = "mm/dd/yyyy";
    $.fn.datepicker.defaults.orientation = "bottom auto";
    $.fn.datepicker.defaults.autoclose = true;

    // load datepicker
    $('input.datepicker').datepicker({
      format: "mm/dd/yyyy",
      orientation: "bottom auto"
    });
  }

  $(document).on('show.bs.tab', function (e) {
    $('[data-toggle="popover"]').popover('hide')
  });

  // bind to payables
  cofiPayable.initialize();
  cofiPayableMulti.initialize();
  cofiPayableFinanced.initialize();
  cofiPaidInvoiceRefresh.initialize();
  console.log('turbo:load')
  if ($("body[data-printable]").length > 0) {
    // if body has data-printable attribute then invoke a print on load
    window.print();
  }

  document.querySelectorAll('.input-dob').forEach(function (el) {
    new Cleave(el, {
      date: true,
      datePattern: ['m', 'd', 'Y']
    });
  });

  // TODO - When we fix stimulus not working everywhere move this
  $("*[data-cofi-mouse-over-toggle]").off("mouseenter").on("mouseenter", function (e) {
    $(e.target.dataset["target"]).addClass("d-visible");
    $(e.target.dataset["target"]).removeClass("d-none");
  }).off("mouseleave").on("mouseleave", function (e) {
    $(e.target.dataset["target"]).removeClass("d-visible");
    $(e.target.dataset["target"]).addClass("d-none");
  })

  // make forms non submit on enter
  window.bindStopFormSubmitAndBack();

});

// Fix for Turbo breaking collapses
window.cofiFixCollapse = function () {
  $("div[data-cofi-toggle=\"collapse\"]").off("click").on("click", function (e) {
    console.log('click')
    e.preventDefault();
    $($(this).data("target")).collapse("toggle");
    return false;
  })
}



$(window).on("popstate", function () {
  var anchor = location.hash || $("a[data-toggle='tab']").first().attr("href");
  $("a[href='" + anchor + "']").tab("show");
});

document.addEventListener("turbo:before-cache", () => {
  cofiSelectPicker.destroy();
  $(".alert").remove();
  $(".has-tooltip").tooltip('dispose');
  $('[data-toggle="popover"]').popover('dispose')
  // Fixes issues with back button
  cofiDestroyDataTables();
});




