$.toggle_account_type = function (element) {
  var selected_option = element.find(":selected"); // get selected option for the changed select only
  selected_value = selected_option.val();
  if (selected_value === "full") {
    $('#omd_practice_invoice_on_day').prop('disabled', true);
    $('#row_ach_on_day, #row_default_invoice_type').addClass('d-none');
  } else if (selected_value === "hybrid") {
    $('#omd_practice_invoice_on_day').prop('disabled', false);
    $('#row_ach_on_day, #row_default_invoice_type').removeClass('d-none');
  } else if (selected_value === "partner_only_financing") {
    $('#omd_practice_invoice_on_day').prop('disabled', false);
    $('#row_ach_on_day').removeClass('d-none');
    $('#row_default_invoice_type').addClass('d-none');
  } else {
    $('#omd_practice_invoice_on_day').prop('disabled', false);
    $('#row_ach_on_day').removeClass('d-none');
    $('#row_default_invoice_type').addClass('d-none');
  }
  $('#omd_practice_invoice_on_day').selectpicker('refresh');
  $('#omd_practice_default_invoice_type').selectpicker('refresh');
}

$.resetValues = function (feeDetailValues) {
  $('#cofi_fee_pct_no_partners input').val(feeDetailValues.cofiFeePctNoPartnersInputValue);
  $('#cofi_fee_pct_with_comanaging_partners input').val(feeDetailValues.cofiFeePctWithComanagingPartnersInputValue);
  $('#cofi_fee_pct_without_comanaging_partners input').val(feeDetailValues.cofiFeePctWithoutComanagingPartnersInputValue);
  $('#cofi_fee_fxd_no_partners_cents input').val(feeDetailValues.cofiFeeFxdNoPartnersInputValue);
  $('#cofi_fee_fxd_with_comanaging_partners_cents input').val(feeDetailValues.cofiFeeFxdWithComanagingPartnersInputValue);
  $('#cofi_fee_fxd_without_comanaging_partners_cents input').val(feeDetailValues.cofiFeeFxdWithoutComanagingPartnersInputValue);
  $('#cofi_fee_pct input').val(feeDetailValues.cofiFeePctInputValue);
  $('#cofi_fee_fxd_cents input').val(feeDetailValues.cofiFeeFxdCentsInputValue);
};

$.toggleCofiFeeInputVisibility = function () {
  const feeTypeSelect = $('#omd_practice_fee_type');
  $('[id^=cofi_fee]').hide();
  $('[id^=cofi_fee] input').prop('disabled', true);

  switch (feeTypeSelect.val()) {
    case 'monthly_subscription_only':
      $.showMonthlySubscriptionFields();
      break;
    case 'tx_fee_percentage':
      $.showFeePctFields();
      break;
    case 'tx_fee_flat':
      $.showFeeFxdFields();
      break;

    default:
      break;
  }
}

$.showMonthlySubscriptionFields = function () {
}

$.showFeePctFields = function () {
  $('#cofi_fee_pct_no_partners, #cofi_fee_pct_with_comanaging_partners, #cofi_fee_pct_without_comanaging_partners, #cofi_fee_pct').show();

  $('#cofi_fee_pct_no_partners input, #cofi_fee_pct_with_comanaging_partners input, #cofi_fee_pct_without_comanaging_partners input, #cofi_fee_pct input').prop('disabled', false);
}

$.showFeeFxdFields = function () {
  $('#cofi_fee_fxd_no_partners_cents, #cofi_fee_fxd_with_comanaging_partners_cents, #cofi_fee_fxd_without_comanaging_partners_cents, #cofi_fee_fxd_cents').show();

  $('#cofi_fee_fxd_no_partners_cents input, #cofi_fee_fxd_with_comanaging_partners_cents input, #cofi_fee_fxd_without_comanaging_partners_cents input, #cofi_fee_fxd_cents input').prop('disabled', false);
}
