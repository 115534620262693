// currency library
import currency from 'currency.js';
// BigDecimal Implementation
import { Decimal } from 'decimal.js';

const D_MAX_COM_PCT = new Decimal("0.20")
const ADJUST_COM_FEE = false

export default class ProductPricingCalculator {
  get ADJUST_COM_FEE() {
    return ADJUST_COM_FEE;
  }
  static formatCurrency(val) {
    val = this.makeCurrency(val)
    return val.format({})
  }
  static normalizeAmount(selector) {
    var val = selector.indexOf("input#") !== -1 ? $(selector).val() : $(selector).text()
    if (val === null || val === undefined || val === "") {
      return currency(0);
    }
    try {
      return currency(val);
    } catch (e) {
      console.error(`ProductPricingCalculator::normalizeAmount - Failed to parse ${val} error: ${e.message} returning zero`);
      return currency(0)
    }
  }
  static makeCurrency(val) {
    if (!(val instanceof currency)) {
      val = currency(val)
    }
    return val;
  }
  static calculateTotals(retail_dollars, comanagement_fee_dollars, facility_fee_dollars, discount_dollars, has_comanaging_od, has_comanaging_facility) {
    console.log(`ProductPricingCalculator::calculateTotals() - CoM OD ${has_comanaging_od} CoM Facility ${has_comanaging_facility}`)
    let c_retail = this.makeCurrency(retail_dollars);
    let c_discount = this.makeCurrency(discount_dollars);
    let c_comanagement_fee = (has_comanaging_od) ? this.makeCurrency(comanagement_fee_dollars) : currency(0);
    let c_comanagement_retail = c_comanagement_fee;
    let c_facility_fee = (has_comanaging_facility) ? this.makeCurrency(facility_fee_dollars) : currency(0);
    let c_surgeon_retail = c_retail.subtract(c_facility_fee).subtract(c_comanagement_fee);
    let c_patient_total = c_retail.subtract(c_discount);
    let d_new_retail = new Decimal(c_retail.subtract(c_discount).subtract(c_facility_fee).value);
    let d_new_com_pct = new Decimal(c_comanagement_fee.value).div(d_new_retail);
    let c_comanagement_disc = currency(0);
    console.log(`ProductPricingCalculator::calculateTotals() - CoM OD ${has_comanaging_od} CoM OD Fee ${c_comanagement_fee} CoM Facility ${has_comanaging_facility} CoM Facility Fee ${c_facility_fee}`)

    let out_of_compliance = false;
    if (d_new_com_pct.gt(D_MAX_COM_PCT)) {
      console.log(`ProductPricingCalculator::calculateTotals() - 20% Exceeded Orig Price ${c_retail} New Price ${d_new_retail} New PCT ${d_new_com_pct}`)
      if (ADJUST_COM_FEE) {
        let d_new_com_fee = d_new_retail.mul(D_MAX_COM_PCT);
        c_comanagement_disc = c_comanagement_fee.subtract(parseFloat(d_new_com_fee.toString()));
        console.log(`ProductPricingCalculator::calculateTotals() - Orig CoM Fee ${c_comanagement_fee} New CoM Fee ${d_new_com_fee} CoM Discount ${c_comanagement_disc}`)
        c_comanagement_fee = currency(d_new_com_fee.toString());
      }
      out_of_compliance = true;
    }
    let c_surgeon_fee = c_retail.subtract(c_discount).subtract(c_facility_fee).subtract(c_comanagement_fee);
    return new ProductPriceDetails(c_retail, c_discount, c_patient_total, c_surgeon_retail, c_surgeon_fee, c_comanagement_retail, c_comanagement_fee, c_comanagement_disc, c_facility_fee, c_facility_fee, out_of_compliance)
  }
}

export class ProductPriceDetails {
  constructor(retail, discount_applied, patient_total, surgeon_retail, surgeon_total, comanagement_retail, comanagement_total, comanagement_discount, facility_retail, facility_total, adjusted_due_to_compliance) {
    this.retail = retail;
    this.discount_applied = discount_applied
    this.patient_total = patient_total;
    this.surgeon_retail = surgeon_retail;
    this.surgeon_total = surgeon_total;
    this.facility_retail = facility_retail;
    this.facility_total = facility_total;
    this.comanagement_retail = comanagement_retail;
    this.comanagement_total = comanagement_total;
    this.comanagement_discount = comanagement_discount;
    this.adjusted_due_to_compliance = adjusted_due_to_compliance
  }
  get retail_formatted() {
    return ProductPricingCalculator.formatCurrency(this.retail);
  }
  get retail_dollars() {
    return this.retail.value;
  }
  get retail_cents() {
    return this.retail.intValue;
  }
  get discount_applied_formatted() {
    return ProductPricingCalculator.formatCurrency(this.discount_applied);
  }
  get discount_applied_dollars() {
    return this.discount_applied.value;
  }
  get discount_applied_cents() {
    return this.discount_applied.intValue;
  }
  get patient_total_formatted() {
    return ProductPricingCalculator.formatCurrency(this.patient_total);
  }
  get patient_total_dollars() {
    return this.patient_total.value;
  }
  get patient_total_cents() {
    return this.patient_total.intValue;
  }
  get surgeon_total_formatted() {
    return ProductPricingCalculator.formatCurrency(this.surgeon_total);
  }
  get surgeon_total_dollars() {
    return this.surgeon_total.value;
  }
  get surgeon_total_cents() {
    return this.surgeon_total.intValue;
  }
  get surgeon_retail_formatted() {
    return ProductPricingCalculator.formatCurrency(this.surgeon_retail);
  }
  get surgeon_retail_dollars() {
    return this.surgeon_retail.value;
  }
  get surgeon_retail_cents() {
    return this.surgeon_retail.intValue;
  }
  get comanagement_retail_formatted() {
    return ProductPricingCalculator.formatCurrency(this.comanagement_retail);
  }
  get comanagement_retail_dollars() {
    return this.comanagement_retail.value;
  }
  get comanagement_retail_cents() {
    return this.comanagement_retail.intValue;
  }
  get comanagement_total_formatted() {
    return ProductPricingCalculator.formatCurrency(this.comanagement_total);
  }
  get comanagement_total_dollars() {
    return this.comanagement_total.value;
  }
  get comanagement_total_cents() {
    return this.comanagement_total.intValue;
  }
  get comanagement_discount_formatted() {
    return ProductPricingCalculator.formatCurrency(this.comanagement_discount);
  }
  get comanagement_discount_dollars() {
    return this.comanagement_discount.value;
  }
  get comanagement_discount_cents() {
    return this.comanagement_discount.intValue;
  }
  get facility_retail_formatted() {
    return ProductPricingCalculator.formatCurrency(this.facility_retail);
  }
  get facility_retail_dollars() {
    return this.facility_retail.value;
  }
  get facility_retail_cents() {
    return this.facility_retail.intValue;
  }
  get facility_total_formatted() {
    return ProductPricingCalculator.formatCurrency(this.facility_total);
  }
  get facility_total_dollars() {
    return this.facility_total.value;
  }
  get facility_total_cents() {
    return this.facility_total.intValue;
  }

  get is_invalid() {
    return this.surgeon_total_cents < 0 || this.comanagement_total_cents < 0;
  }
  get is_adjusted_for_compliance() {
    return this.adjusted_due_to_compliance
  }

}

export class FacilityProductCalculator {
  static sum_dollars(values) {
    return this.sum(false, values);
  }
  static sum_cents(values) {
    return this.sum(true, values);
  }
  static sum(in_cents, values) {
    let sum = currency(0);
    values.forEach(elem => {
      sum = sum.add(elem);
    });
    // console.log(`sum cents ${sum.intValue} dollars ${sum.value}`);
    if (in_cents) {
      return sum.intValue;
    } else {
      return sum.value.toFixed(2);
    }
  }
}