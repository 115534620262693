class FinancedPayable {
    initialize() {
    }
}

export class FinancedStatusUpdater {
  constructor(statusUrl, callback = null) {
    console.log('here');
    this.statusUrl = statusUrl
    this.intervalId = setInterval(() => {
      self = this;
      $.ajax({
        url: this.statusUrl,
        context: this,
        dataType: "json"
      }).done(function (data) {
        self.updateUI(data);
      });
    }, 3000)
    this.callback = callback;
    this.boundUrl = window.location.href;
  }
  updateUI(data) {
    if (this.boundUrl !== window.location.href) {
      console.warn('canceling status updates due to page navigation change, probably due to turbolinks');
      clearInterval(this.intervalId);
      this.callback(false, 'page_change', data);
    } else if (data.has_errors || data.financed_transaction_id !== null) {
      clearInterval(this.intervalId)
      if (this.callback !== null) {
        this.callback(true, data)
      }
    } else {
      if (this.callback !== null) {
        this.callback(false, data);
      }
    }
  }
}
export default new FinancedPayable()
