// Partial Refund Modal JS

$.validate_refund_amount = function (refundable_amounts) {
  const inputAmounts = $.get_entered_amounts();
  let fullAmountEntered = 0;
  let hasError = false;
  let errorMessage = "";

  Object.entries(refundable_amounts).forEach(function ([key, refundable_amount]) {
    const inputAmount = parseFloat(inputAmounts[key]);

    if (inputAmount > refundable_amount) {
      errorMessage += $.toggleErrorText(true, false, refundable_amount) + "<br>";
      $(`[id$=${key}`).addClass('error');
      hasError = true;
    } else {
      $(`[id$=${key}`).removeClass('error');
      if (inputAmount === refundable_amount) {
        fullAmountEntered++;
      }
    }
  })

  if (fullAmountEntered === $('[id^=custom-amount-value-]').length) {
    errorMessage += $.toggleErrorText(false, true);
    $('[id^=custom-amount-value-]').addClass('error');
    hasError = true;
  }

  $('#error-text').html(errorMessage);
  return hasError;
}

$.custom_input_valid = function () {
  const customInputs = $('input[id^="custom-amount-value"]');
  let isValid = false;

  for (const customInput of customInputs) {
    const inputValue = parseFloat(customInput.value);

    if (isNaN(inputValue))
      return false;

    if (inputValue <= 0)
      continue;

    isValid = true;
  }

  return isValid;
}

$.validate_custom_input = function () {
  let disabled = false;
  if (!$.custom_input_valid()) {
    disabled = true;
  }
  $.toggle_custom_input(true, disabled);
}

$.toggleErrorText = function (max_amount, full_amount, refund_amount) {
  if (max_amount) {
    refund_amount = '$' + parseFloat(refund_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return `The maximum amount that can be refunded is ${refund_amount}.`
  } else if (full_amount) {
    return 'Please select “Amount Paid To You” to refund your entire amount.'
  } else {
    return ''
  }
}

$.reset_refund_modal = function () {
  $('#refund-portion-form').trigger("reset");
  $('[id^=error-text]').text('');
  $('#submit-refund-form').prop('disabled', true);
  $('[id^=custom-amount-value-]').prop('disabled', true);
  $('[id^=custom-amount-value-]').removeClass('error');
}

$.toggle_custom_input = function (toggle, disabled = false) {
  $('[id^=custom-amount-value').prop('disabled', !toggle);
  $('#submit-refund-form').prop('disabled', disabled);
}

$.get_form_data_partner = function (splitPayment, shoppersInfo) {
  const enteredAmounts = $.get_entered_amounts();

  if (splitPayment)
    return {
      partner_amount_card1: enteredAmounts['partner-card1'],
      partner_amount_card2: enteredAmounts['partner-card2'],
      card1_sp_shopper_id: shoppersInfo.card1_sp_shopper_id,
      card2_sp_shopper_id: shoppersInfo.card2_sp_shopper_id,
      card1_last_four_digits: shoppersInfo.card1_last_four_digits,
      card2_last_four_digits: shoppersInfo.card2_last_four_digits
    };

  return {
    amount: enteredAmounts['partner-card1']
  };
};


$.submit_refund_form = function (fullRefundPath, partialRefundPath, refundableAmounts, shoppersInfo, splitPayment) {
  if ($('#fixed-amount').is(':checked')) {
    $.refund_fixed_amount(fullRefundPath, refundableAmounts);
  } else if ($('#custom-amount').is(':checked')) {
    $.refund_custom_amount(partialRefundPath, refundableAmounts, shoppersInfo, splitPayment);
  }
}

$.refund_fixed_amount = function (refund_path, refundableAmounts) {
  $('#refund-portion-modal').modal('hide');
  // send ajax request to refund fixed amount
  $.send_refund_request(refund_path, { partner_amount_full: refundableAmounts.partner_amount_full });
}

$.refund_custom_amount = function (refund_path, refundableAmounts, shoppersInfo, splitPayment) {
  if ($.validate_refund_amount(refundableAmounts))
    return false;

  $('#refund-portion-modal').modal('hide');
  // send ajax request to refund custom amount
  $.send_refund_request(refund_path, $.get_form_data_partner(splitPayment, shoppersInfo));
}

$.send_refund_request = function (refund_path, requestData) {
  $.ajax({
    url: refund_path,
    type: 'POST',
    remote: true,
    dataType: 'json',
    data: requestData,
    success: function (data) {
      location.reload();
    },
    error: function (data) {
      console.log("Custom refund request failed")
    }
  })
}

// OMD Refund Modal JS

$.toggle_empty_fields_error = function () {
  $('#partial-refund-form input').each(function () {
    if ($(this).val() === '') {
      $(this).addClass('error');
    }
  });
}

$.get_entered_amounts = function () {
  var amounts = {};
  $('input[id^="custom-amount-value-"]').each(function () {
    var amount = $(this).val().replace(/^\$?/, '').replace(/,/g, '').replace(/^(0+\.?0*)$/, '0');
    var id = $(this).attr('id').split('-').slice(-2).join('-');
    amounts[id] = amount;
  });

  return amounts;
}

$.clear_errors = function () {
  $('[id^=custom-amount-value-]').removeClass('error');
  $('#amount-error').text('');
  $('#reason-error').addClass('d-none');
}

$.empty_fields_check = function () {
  var filledInputCount = 0;

  $('#partial-refund-form input').each(function () {
    if ($(this).val() != '') {
      filledInputCount += 1;
    }
  });

  if (filledInputCount === 0) {
    $.toggle_empty_fields_error();
    return false;
  }
  return true
}

$.all_zeros_check = function () {
  var allZeros = true;

  $('#partial-refund-form input').each(function () {
    if (parseFloat($(this).val()) != 0) {
      allZeros = false;
    }
  });

  if (allZeros) {
    $('[id^=custom-amount-value-]').addClass('error');
    return false;
  }
  return true
}

$.empty_field_check = function () {
  var filledInputCount = 0;
  const totalInputCount = parseFloat($('#partial-refund-form input').length);

  $('#partial-refund-form input').each(function () {
    if ($(this).val() != '') {
      filledInputCount += 1;
    }
  });

  if (filledInputCount != totalInputCount) {
    $.toggle_empty_fields_error();
    return false;
  }
  return true;
}

$.valid_amount_check = function () {
  var validAmount = true;
  var enteredAmounts = $.get_entered_amounts();

  Object.entries(enteredAmounts).forEach(function ([key, enteredAmount]) {
    if (!/^(?!0\d)(\d*\.?\d{1,2}|0)$/.test(enteredAmount)) {
      $(`#custom-amount-value-${key}`).addClass('error');
      validAmount = false;
    }
  });
  return validAmount;
}

$.toggle_reason_box = function () {
  var showReasonBox = false;

  $('#partial-refund-form input').each(function () {
    if ($(this).val() === '' || $(this).attr("id").startsWith("custom-amount-value-OmdPractice")) {
      return;
    }
    if (parseFloat($(this).val()) != 0) {
      showReasonBox = true;
    }
  });

  if (showReasonBox) {
    $('#reason-text').removeClass('d-none');
  } else {
    $('#reason-text').addClass('d-none');
  }
};

$.refund_box_check = function () {
  if (!$('#reason-text').hasClass('d-none')) {
    if ($('textarea[name="refund_reason"]').val() === '') {
      $('#reason-error').removeClass('d-none');
      return false;
    }
  }
  return true;
}

$.get_form_data = function (split_payment, splitPaymentShoppers) {
  var enteredAmounts = $.get_entered_amounts();

  if (split_payment) {
    return {
      omd_amount_card1: enteredAmounts['OmdPractice-card1'] || 0,
      omd_amount_card2: enteredAmounts['OmdPractice-card2'] || 0,
      omd_amount_full: enteredAmounts['OmdPractice-combined'] || 0,
      od_amount_card1: enteredAmounts['OdPractice-card1'] || 0,
      od_amount_card2: enteredAmounts['OdPractice-card2'] || 0,
      od_amount_full: enteredAmounts['OdPractice-combined'] || 0,
      asc_amount_card1: enteredAmounts['Asc-card1'] || 0,
      asc_amount_card2: enteredAmounts['Asc-card2'] || 0,
      asc_amount_full: enteredAmounts['Asc-combined'] || 0,
      anesthesia_provider_amount_card1: enteredAmounts['ServiceProvider-card1'] || 0,
      anesthesia_provider_amount_card2: enteredAmounts['ServiceProvider-card2'] || 0,
      anesthesia_provider_amount_full: enteredAmounts['ServiceProvider-combined'] || 0,
      reason: $('textarea[id="refund_reason"]').val(),
      card1_last_four_digits: splitPaymentShoppers[0]["cc_last_four"],
      card2_last_four_digits: splitPaymentShoppers[1]["cc_last_four"],
      card1_sp_shopper_id: splitPaymentShoppers[0]["id"],
      card2_sp_shopper_id: splitPaymentShoppers[1]["id"]
    }
  } else {
    return {
      omd_amount: enteredAmounts["OmdPractice-card1"] || 0,
      od_amount: enteredAmounts["OdPractice-card1"] || 0,
      asc_amount: enteredAmounts["Asc-card1"] || 0,
      anesthesia_provider_amount: enteredAmounts["ServiceProvider-card1"] || 0,
      reason: $('textarea[id="refund_reason"]').val()
    }
  }
}

$.reset_modal = function () {
  $('#partial-refund-form input').each(function () {
    $(this).val('');
  });

  $('#amount-error').text('');
  $('#reason-error').addClass('d-none');
  $('#reason-text').addClass('d-none');
  $('textarea[id="refund_reason"]').val('');
}

$.validate_input = function (refundableAmount, enteredAmount, partiallyRefunded, txnableType, errorMessages, amountsValid, fullAmountEntered, card, splitRefundAvailable, partial_refund_manually, error_map) {

  if (enteredAmount > refundableAmount) {
    if (splitRefundAvailable)
      errorMessages.add(error_map.exceeds_refundable_multi_card);
    else
      errorMessages.add(error_map.exceeds_refundable);
    $(`#custom-amount-value-${txnableType}-${card}`).addClass('error');
    amountsValid = false;
  }

  if (partiallyRefunded && enteredAmount !== refundableAmount && enteredAmount !== 0) {
    if (partial_refund_manually) {
      switch (txnableType) {
        case 'OmdPractice':
          errorMessages.add(error_map.partially_failed_refunds_Omd);
          break;
        case 'OdPractice':
          errorMessages.add(error_map.partially_failed_refunds_Od);
          break;
        case 'Asc':
          errorMessages.add(error_map.partially_failed_refunds_Asc);
          break;
        case 'ServiceProvider':
          errorMessages.add(error_map.partially_failed_refunds_Anes);
          break;
        default:
          break;
      }
    }
    else errorMessages.add(error_map.multiple_refunds);
    $(`#custom-amount-value-${txnableType}-${card}`).addClass('error');
    amountsValid = false;
  }

  if (enteredAmount === refundableAmount)
    fullAmountEntered++;

  return { amountsValid: amountsValid, fullAmountEntered: fullAmountEntered }
}

$.entered_amount_checks = function (errorMessages, error_map, pay_ins_filtered) {
  var fullAmountEnteredCount = 0;
  var amountsValid = true;
  const enteredAmounts = $.get_entered_amounts();

  for (const pay_in of pay_ins_filtered) {
    const splitRefundAvailable = pay_in.split_refund_available;
    const txnableType = pay_in.txnable_type;
    const partial_refund_manually = pay_in.partial_refund_manually_or_failed;
    const refundablePaymentEntries = [
      { type: "card1", amount: pay_in.refundable_amount_card1, partiallyRefunded: pay_in.partially_refunded_card1 },
      { type: "card2", amount: pay_in.refundable_amount_card2, partiallyRefunded: pay_in.partially_refunded_card2 },
      { type: "combined", amount: pay_in.refundable_amount_card_combined, partiallyRefunded: pay_in.refunded_combined },
    ];

    for (const refundablePaymentEntry of refundablePaymentEntries) {
      const enteredAmount = parseFloat(enteredAmounts[`${txnableType}-${refundablePaymentEntry.type}`]);

      if (isNaN(refundablePaymentEntry.amount) || isNaN(enteredAmount)) continue;

      const result = $.validate_input(
        refundablePaymentEntry.amount,
        enteredAmount,
        refundablePaymentEntry.partiallyRefunded,
        txnableType,
        errorMessages,
        amountsValid,
        fullAmountEnteredCount,
        refundablePaymentEntry.type,
        splitRefundAvailable,
        partial_refund_manually,
        error_map);

      amountsValid = result.amountsValid;
      fullAmountEnteredCount = result.fullAmountEntered;
    }
  };

  if (fullAmountEnteredCount === $('[id^=custom-amount-value-]').length) {
    errorMessages.add(error_map.full_refund);
    $('[id^=custom-amount-value-]').addClass('error');
    return false;
  }
  return amountsValid;
}

$.validate_inputs = function (split_pay, error_map, pay_ins_filtered) {
  var isValid = true;
  var errorMessages = new Set();

  if (!$.empty_fields_check()) {
    if (split_pay)
      errorMessages.add(error_map.empty_fields_split);
    else
      errorMessages.add(error_map.empty_fields);
    isValid = false;
  }
  else if (!$.empty_field_check()) {
    errorMessages.add(error_map.empty_field);
    isValid = false;
  }
  else if (!$.valid_amount_check()) {
    errorMessages.add(error_map.invalid_amount);
    isValid = false;
  }

  if (!$.all_zeros_check()) {
    errorMessages.add(error_map.all_zero_amounts);
    isValid = false;
  }

  if (!$.entered_amount_checks(errorMessages, error_map, pay_ins_filtered))
    isValid = false;

  if (!$.refund_box_check())
    isValid = false;

  const sortedErrorMessages = Array.from(errorMessages).sort((a, b) => a.length - b.length);
  sortedErrorMessages.forEach(function (message) {
    $('#amount-error').append(`<p>${message}</p>`);
  });

  return isValid;
}
