/* Adds X-CSRF-TOKEN to all AJAX Requests */
module.exports = function() {
  if (window.$ || false) {
    window.$.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });
  } else {
    console.error("JQuery Not defined, define it first")
  }
}


