
const KEY_STOP_CONFIG = new Map([
  [8,":not(input:text, textarea, input:file, input:password)"], // stop backspace = back
  [13,"input:text, input:password, input:radio, select"] // stop enter = submit
]);

function blockKeys(event) {
  var selector = KEY_STOP_CONFIG.get(event.which);
  if(selector !== undefined && $(event.target).is(selector)) {
      event.preventDefault(); //stop event
      return false;
  }
  return true;
}

export const DEFAULT_BLOCKING_SELECTOR =  "*[data-cofi-block-enter]";

export function bindStopFormSubmitAndBack(selector=DEFAULT_BLOCKING_SELECTOR) {
  $(selector).off("keydown").on("keydown", blockKeys)
}



