$.manage_carecredit_eye_toggle = function(){
  $('#care_credit_ssn_masked_toggle').toggleClass("fa-eye fa-eye-slash");
  $('#ssn_masked').toggleClass("care-credit-masked-field");
  $.careCreditSSN_visibility();
  $("#ssn_masked").focus();
}

$.maskCareCreditSSN = function(index){
  var ssn_val = $('#ssn').val()
  var formatted_val = '';
  if(!index && ssn_val.length != 0){
    index = ssn_val.length - 1 
  }
  for (var i = 0; i < 9; i++) {
    if (ssn_val[i]) {
      formatted_val += ssn_val[i];
    } else {
      formatted_val += 'X';
    }
  }
  if(index > -1 && index < 5 || ssn_val.length < 5){
    masked_val = formatted_val.slice(0,index).replace(/[0-9]/g,'X') + ssn_val[index] + formatted_val.slice(index+1 , 5).replace(/[0-9]/g,'X') + formatted_val.slice(5, 9)
    masked_val = masked_val.slice(0,3) + "-" + masked_val.slice(3,5) + "-" + masked_val.slice(5,9)
  }else{
    masked_val = formatted_val.slice(0,3).replace(/[0-9]/g,'X') + "-" + formatted_val.slice(3,5).replace(/[0-9]/g,'X') + "-" + formatted_val.slice(5,9)
  }
  $('#ssn_masked').val(masked_val.replace(/-+$/, ''))
}

$.maintainCareCreditSSN = function(key, keyCode, obj){
  var ssn = $("#ssn").val() ?? ''
  var ssn = $('#ssn').val() ?? ''
  if (!(/^[0-9]$/i.test(key)) && keyCode != 8) { return } 
  if (ssn.length > 8 && keyCode != 8) { return } 
  old_value = $(`#ssn_masked`).val();
  if (keyCode == 8){
    if (obj.selectionStart !== obj.selectionEnd) {
      $.clearSsnSelection(obj.selectionStart, obj.selectionEnd)
      if(!$('#care_credit_ssn_masked_toggle').hasClass("fa-eye")){
        $.unmaskCareCreditSSN(obj, keyCode)
        return
      }
    }else{
      if(obj.selectionStart == 7 && obj.selectionEnd == 7){
        obj.selectionStart = 6
        obj.selectionEnd = 6
      }
      if(obj.selectionStart == 4 && obj.selectionEnd == 4){
        obj.selectionStart = 3
        obj.selectionEnd = 3
      }
      if(obj.selectionStart == 0 && obj.selectionEnd == 0){
        return
      }
      if (obj && obj.selectionStart !== old_value.length && !$('#care_credit_ssn_masked_toggle').hasClass("fa-eye")) {
        let cursorPosition = obj.selectionStart;
        let newValue = old_value.slice(0, cursorPosition - 1) + old_value.slice(cursorPosition)
        $('#ssn').val(newValue.replace(/[^0-9]/g, ''))
        $.unmaskCareCreditSSN(obj, keyCode)
        return
      }else{
          $('#ssn').val(ssn.slice(0,ssn.length-1))
      }
    }
    $.careCreditSSN_visibility();
  }
  else{
    if (obj && obj.selectionStart !== old_value.length && !$('#care_credit_ssn_masked_toggle').hasClass("fa-eye")) {
      if (obj && obj.selectionStart !== old_value.length) {
        let cursorPosition = obj.selectionStart;
        let newValue = old_value.slice(0, cursorPosition) + key + old_value.slice(cursorPosition);
        $('#ssn').val(newValue.replace(/[^0-9]/g, ''))
        $.unmaskCareCreditSSN(obj, keyCode)
        return
      }else{
        $('#ssn').val(ssn + key)
      }
    }else{
      $('#ssn').val(ssn + key)
    }
    $.careCreditSSN_visibility(ssn.length);
  }
}

$.clearSsnSelection = function(selectionStart, selectionEnd){
  let old_val = $(`#ssn_masked`).val().slice(selectionStart, selectionEnd)
  if(old_val == '-'){
    return
  }
  if (selectionStart > 2) {
    selectionStart -= (selectionStart < 6) ? 1 : 2;
  }
  
  if (selectionEnd > 3) {
    selectionEnd -= (selectionEnd <= 6) ? 1 : 2;
  }
  let maskedValue = $(`#ssn`).val().split('')
  maskedValue.splice(selectionStart, selectionEnd - selectionStart);
  maskedValue = maskedValue.join('');
  maskedValue = maskedValue.replace(/\D/g, "");
  $(`#ssn`).val(maskedValue)
}


$.unmaskCareCreditSSN = function(obj, keyCode){
  let old_val = $('#ssn_masked').val()
  if(obj){
    var cursor = obj.selectionStart
    var cursorEnd = obj.selectionEnd
  }
  var ssn_val = $('#ssn').val()
  masked_val = ssn_val.slice(0,3) + "-" + ssn_val.slice(3,5)+ "-" + ssn_val.slice(5,9)
  $('#ssn_masked').val(masked_val.replace(/-+$/, ''))
  if(obj && cursor !== old_val.length){
    let newCursorPosition = cursor + 1
    if(keyCode == 8){
      newCursorPosition = cursor - 1
      if(cursor != cursorEnd){
        if(obj.selectionStart!=obj.selectionEnd){
          newCursorPosition = cursor + 1
        }else{
          newCursorPosition = cursor
        }
      }
      else{
        if(cursor < 1){
         return
       }
      }
      if(newCursorPosition == 4 || newCursorPosition == 7){
        newCursorPosition = newCursorPosition - 1
      }
    }else{
      newCursorPosition = cursor + 1
      if(newCursorPosition == 4 || newCursorPosition == 7){
        newCursorPosition = newCursorPosition + 1
      }
      if(old_val == masked_val.replace(/-+$/, '').length){
        $('#ssn_masked').prop('selectionStart', cursor);
        $('#ssn_masked').prop('selectionEnd', cursor);
        return
      }
    }
    $('#ssn_masked').prop('selectionStart', newCursorPosition);
    $('#ssn_masked').prop('selectionEnd', newCursorPosition);
  }
}

$.careCreditSSN_visibility = function(index){
  if($('#care_credit_ssn_masked_toggle').hasClass("fa-eye")){
    $.maskCareCreditSSN(index)
  }
  else{
    $.unmaskCareCreditSSN()
  }
}

$.maskCareCreditFields = function(id, pattern, placeholder){
  const field = document.getElementById(id);
  if (field) {
    Inputmask(pattern, { placeholder: placeholder }).mask(field);
  }
}

let transitionTimeout;

$.transitionEndHandler = function() {
  $('.hover-logo').removeClass('d-none');
  $('.normal-logo').addClass('d-none');
};

$.mouseEnterHandler = function() {
  transitionTimeout = setTimeout(function() {
    $.transitionEndHandler();
  }, 90);
};

$.mouseLeaveHandler = function() {
  clearTimeout(transitionTimeout);
  $('.hover-logo').addClass('d-none');
  $('.normal-logo').removeClass('d-none');
};
